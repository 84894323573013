/* You can add global styles to this file, and also import other style files */


@font-face {
    font-family: rlway;
    src: url('Raleway.ttf') format("opentype");
}


@font-face {
    font-family: rlway-regular;
    src: url('Raleway-Regular.ttf') format("opentype");
}


@font-face {
    font-family: lkwd-sf-regular;
    src: url('sf.ttf') format("opentype");
}


* { font-family: 'rlway' !important; 
    /* Add !important to overwrite all elements */  
}


label, p {
    color: white !important;
    opacity: 60%;
}


.form-control {
    background-color: #001d2b;
    border-color: transparent; 
}


input {
    border: none !important;
}


input:focus {
    background-color: #001d2b !important;
    color:white;
    opacity: 70%;
}


input, select, textarea{
    color: white !important;
    opacity: 70%;
}


.form-control:focus, .btn:focus{
    border-color: transparent; 
     box-shadow: none; 
     -webkit-box-shadow: none;
}


html, body {
    height: 100%;
    margin: 0;
}


.lkwd-link {
    color: #00AF97;
}


.lkwd-link:hover {
    color: #00AF97;
}


.title-label {
    font-size: 24px;
    color: white;
    opacity: 87%;
}


.subtitle-lable {
    color: white; 
    opacity: 60%;
    font-size: 20px;
}


.lakewood-input {
    height: 53px;
    border-radius: 0;
    border:'none' !important;
}


input[type=number] {
    -moz-appearance: textfield;
  }


/* width */


::-webkit-scrollbar {
    width: 10px;
  }


/* Track */


::-webkit-scrollbar-track {
    background: #001d2b; 
  }


/* Handle */


::-webkit-scrollbar-thumb {
    background: #888; 
  }


/* Handle on hover */


::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }


hr { 
    display: block;
    height: 1px;
    border: 0; border-top: 1px solid #ffff;
    opacity: 20%;
    margin: 1em 0; padding: 0; 
}


.lkwd-text-small {
    color: white;
    opacity: 60%;
    font-size: 14px
}


.lkwd-text-medium {
    color: white;
    opacity: 87%;
    font-size: 16px
}


.error-feedback {
    color: #E46C6C !important;
    
}


/* Chrome, Safari, Edge, Opera */


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 30px #001d2b inset !important;
    -webkit-transition: none !important;
    -webkit-text-fill-color: white;
    background-color: #001d2b !important;
}


input:focus {
    background-color: #001d2b !important;
    color:white;
    opacity: 70%;
}


/* Firefox */


input[type=number] {
  -moz-appearance: textfield;
}